import { Auth } from '@aws-amplify/auth';
import clsx, { ClassValue } from 'clsx';
import Cookies from 'js-cookie';
import moment from 'moment';
import { matchPath } from 'react-router';
import store from 'store2';
import { twMerge } from 'tailwind-merge';
import { AppConfig, BrandConfiguration } from 'toca-config';
import { ISurchargeConditions } from 'toca-shared-ui';
import { Events } from 'toca-shared-utils';
import { BreakpointsNumeric } from 'toca-themes';
import { EBrandId } from 'types/brandId';
import uuid from 'uuid/v4';
import * as Constants from '../../src/config/Constants';
import Routes from '../config/Routes';

export const truncateDecimal = (value: number, precision: number) => {
  var step = Math.pow(10, precision || 0);
  var temp = Math.trunc(step * value);

  return temp / step;
};

const getUserRoles = async () => {
  const currentSession = await Auth.currentSession();
  return JSON.parse(currentSession.getIdToken().payload['custom:roles']);
};

const userHasRole = async (roleId: number) => {
  let hasRole = false;
  const roles = await getUserRoles();
  roles.map((role: any) => {
    if (role.hasOwnProperty(roleId)) {
      hasRole = true;
    }
  });
  return hasRole;
};

export const setStateByRole = (setStateCallBack: Function, roleId: number) => {
  userHasRole(roleId).then(value => {
    setStateCallBack(value);
  });
};

export const getTargetUserId = () => {
  return store.session.getAll([Constants.impersonateInfo.targetUserId]).targetUserId;
};

export const getLegacyCreateNewOrderUrl = async (studentId: string, supplierServiceId: string) => {
  const session = await Auth.currentSession();
  const jwt = session.getIdToken().getJwtToken();
  const tocaUserId = await session.getIdToken().payload['custom:toca_user_id'];

  let url = `${AppConfig.flexischoolsApiBaseUrl}api/orders/create/${tocaUserId}/${supplierServiceId}?orderUserId=${studentId}&cancelExisting=true&token=${jwt}&returnUrl=https://logoutflexischools.com/`;

  const targetUserId = getTargetUserId();
  if (targetUserId) {
    url = `${url}&targetUserId=${targetUserId}`;
  }
  return url;
};

export const isUserCustomerRep = (setStateCallBack: Function) => {
  setStateByRole(setStateCallBack, 16);
};

export const is_iOSDevice = () => {
  const is_iOSDevice =
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  return is_iOSDevice;
};

export const getIframeHeight = () => {
  if (is_iOSDevice()) {
    // let dpr = window.devicePixelRatio || 1;
    // return `${window.screen.height * dpr}px`;

    return `${document.documentElement.clientHeight - 100}px`;
  } else {
    return `${
      window.innerWidth >= BreakpointsNumeric.medium
        ? window.innerHeight - 130
        : window.innerHeight - 100
    }px`;
  }
};

export const isReturnUrlNotUserPortal = (returnUrl: string) => {
  return !returnUrl.includes(BrandConfiguration.getUserPortalUrl());
};

export const FormatToCurrency = (num: any) => {
  return num ? parseFloat(num).toFixed(2) : '0.00';
};

export const isValidRoute = (route: string): Boolean => {
  var routesArray = Object.values(Routes);

  const match = matchPath(route, {
    path: routesArray,
    exact: true,
    strict: false,
  });

  return !!match;
};

export const goToNativeProfileScreen = () => {
  window.location.replace(`${BrandConfiguration.getUserPortalUrl()}${Routes.nativeProfile}`);
};

export const isEmailFlxUserName = (email: string): boolean => {
  return email.includes('@flxcards.com.au');
};

export const publishUpdateClassWebViewEvent = () => {
  Events.postMessageToWebView('update_class_list');
};

export const toHumanisedDate = (date: Date) => {
  const dateMoment = moment(
    date
      .toString()
      .replace('T', ' ')
      .replace('Z', ''),
    'YYYY-MM-DD HH:mm:ss'
  );
  const daysFromToday = moment(dateMoment.format('YYYY-MM-DD'), 'YYYY-MM-DD').diff(
    moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD'),
    'days'
  );
  if (daysFromToday === 0) {
    return 'Today';
  } else if (daysFromToday === 1) {
    return 'Tomorrow';
  } else {
    return dateMoment.format('DD/MM');
  }
};

export const userAsParent = () => {
  const brand = BrandConfiguration.GetBrandConfiguration();
  return brand.brandConfiguration.userAsParent;
};

export const getChannelName = () => {
  const defaultChannel = 'USERPORTAL';
  const injectedChannelCookieName = 'channel';
  const channelFromFsApp = Cookies.get(injectedChannelCookieName);

  return channelFromFsApp || defaultChannel;
};

export const sleep = (duration = 0) => {
  return new Promise(resolve => setTimeout(resolve, duration));
};

export function getSurchargeAmount(
  purchaseAmount: number,
  surchargeConditions?: ISurchargeConditions
) {
  let surchargeAmount = 0;

  if (surchargeConditions) {
    const { fixedFee, variableFee } = surchargeConditions;
    surchargeAmount = fixedFee + variableFee * purchaseAmount;
  }
  return Number(surchargeAmount.toFixed(2));
}

export function getGuid() {
  return uuid();
}

export function getFormattedErrorMessage(
  error: string | null | undefined,
  prefixToRemove = 'uierror:'
) {
  if (!error) {
    return null;
  }
  const regex = new RegExp(`(.|\n)*${prefixToRemove}`, 'i');
  const result = error.replace(regex, '');

  return result.trim();
}

export function getImpersonationUrlToOrderingPortal(
  redirectUrl: string,
  options: {
    targetBrandId: string;
    targetUserId: string;
    targetUserKey: string;
    searchParams?: URLSearchParams;
  }
) {
  const {
    targetBrandId,
    targetUserId,
    targetUserKey,
    searchParams = new URLSearchParams(),
  } = options;
  searchParams.append('targetBrandId', targetBrandId);
  searchParams.append('targetUserId', targetUserId);
  searchParams.append('targetUserKey', targetUserKey);
  searchParams.append('redirectUrl', redirectUrl);
  redirectUrl = `/impersonate`;
  let queryString = searchParams.toString();
  if (queryString) {
    redirectUrl += `?${queryString}`;
  }

  return `${BrandConfiguration.getOrderingPortalUrl()}${redirectUrl}`;
}

export const getIsWhiteListedDomain = (redirectLink: string) => {
  try {
    const url = new URL(redirectLink);
    const whiteListedDomains = [
      AppConfig.supplierPortalUrl,
      BrandConfiguration.getOrderingPortalUrl(),
      AppConfig.opsPortalUrl,
      AppConfig.flexibuzzWebBaseUrl,
      AppConfig.cdfPayUserPortalUrl,
    ];
    return whiteListedDomains.some(domain => domain.includes(url.host));
  } catch (error) {
    console.log('Error checking whitelisted domain', error);
    return false;
  }
};

export const isBuzzEnabledForBrand = (brandId: string) => {
  return brandId !== EBrandId.CDFPAY;
};

/**
 * Combines multiple class names into a single string, merging Tailwind CSS classes as needed.
 *
 * This function uses `clsx` to conditionally combine class names and `twMerge` to merge
 * Tailwind CSS classes, ensuring that conflicting classes are handled correctly.
 *
 * @param {...ClassValue[]} inputs - The class names to combine. These can be strings, arrays, or objects.
 * @returns {string} The combined class names.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
